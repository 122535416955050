// component
import Iconify from '../../components/Iconify'

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Add Company',
    path: '/dashboard/add-company/:id',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'Admin',
    path: '/dashboard/inventory',
    icon: getIcon('fa6-solid:file-invoice-dollar'),
  },
  {
    title: 'register',
    path: '/register',
    icon: getIcon('eva:person-add-fill'),
  },
]

export default navConfig
