import React, { useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useReactToPrint } from 'react-to-print'
import { Button, Grid } from '@mui/material'
import Inventory from '../components/Inventory'

function ReportPrint() {
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const navigate = useNavigate()
  const { id } = useParams()
  return (
    <div className="bg-gray-200 p-6">
      <Grid container sx={{ justifyContent: 'space-between' }}>
        <Grid item>
          <Button type="button" variant="contained" onClick={handlePrint}>
            {' '}
            Print Report{' '}
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="button"
            color="warning"
            variant="contained"
            onClick={() => navigate(`/dashboard/inventory-form/${id}`)}
          >
            Edit Form
          </Button>
        </Grid>
      </Grid>

      <Inventory ref={componentRef} />
    </div>
  )
}
export default ReportPrint
