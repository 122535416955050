import { Container } from '@mui/system'
import { useState, useEffect } from 'react'
import {
  Paper,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Divider,
} from '@mui/material'
import Page from '../components/Page'
import Modal from '../components/Modal'
import { useParams, useNavigate } from 'react-router'

//Hooks
import { useDocument } from '../hooks/useDocument'
import { useFirestore } from '../hooks/useFirestore'

function Update() {
  const [companyName, setCompanyName] = useState('')
  const [branch, setBranch] = useState('')
  const [fault, setFault] = useState('')
  const [inspection, setInspection] = useState()
  const [recommendation, setRecommendation] = useState('')
  const [conclusion, setConclusion] = useState('')
  const [equipment, setEquipment] = useState('')
  const [equipmentModel, setEquipmentModel] = useState('')
  const [equipmentMake, setEquipmentMake] = useState('')
  const [equipmentSN, setEquipmentSN] = useState('')
  const [date, setDate] = useState('')
  const [service, setService] = useState('')
  const [rConclusion, setRConclusion] = useState('')
  const [rRecommendation, setRRecommendation] = useState('')
  const [rDate, setRDate] = useState('')
  const [history, setHistory] = useState('')
  const [observation, setObservation] = useState('')
  const [part, setPart] = useState('')
  const [carried, setCarried] = useState('')

  const { id, company } = useParams()
  const navigate = useNavigate()
  const { document } = useDocument(company)
  const { updateDocument, message, isError, isPending } = useFirestore(company)

  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await document(id)
        // console.log(docSnap.data())
        setCompanyName(docSnap.data().companyName)
        setBranch(docSnap.data().branch)
        setFault(docSnap.data().fault)
        setInspection(docSnap.data().inspection)
        setRecommendation(docSnap.data().recommendation)
        setConclusion(docSnap.data().conclusion)
        setEquipment(docSnap.data().equipment)
        setEquipmentModel(docSnap.data().equipmentModel)
        setEquipmentMake(docSnap.data().equipmentMake)
        setEquipmentSN(docSnap.data().equipmentSN)
        setDate(docSnap.data().date)
        setService(docSnap.data().service)
        setRConclusion(docSnap.data().rConclusion)
        setRRecommendation(docSnap.data().rRecommendation)
        setRDate(docSnap.data().rDate)
        setHistory(docSnap.data().history)
        setObservation(docSnap.data().observation)
        setPart(docSnap.data().part)
        setCarried(docSnap.data().carried)
      } catch (error) {
        console.log(error)
      }
    }
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
    }

    // eslint-disable-next-line
  }, [id])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const inspect = {
      companyName,
      branch,
      fault,
      inspection,
      recommendation,
      conclusion,
      date,
      equipment,
      equipmentModel,
      equipmentMake,
      equipmentSN,
      service,
      rConclusion,
      rRecommendation,
      rDate,
      history,
      observation,
      part,
      carried,
    }
    // console.log(inspect)
    try {
      if ((id !== undefined || id !== '') && id !== ':id') {
        await updateDocument(id, inspect)
      }
    } catch (error) {
      console.log('msg', error.message)
    }
  }
  useEffect(() => {
    if (message) {
      setOpen(true)
      setTimeout(() => {
        navigate(`/dashboard/report/${id}/${companyName}`, {
          replace: true,
        })
      }, 5000)
    }
  }, [message, navigate, id, companyName])

  return (
    <Page title="Update">
      <Container maxWidth="xl">
        <Box component="form" onSubmit={handleSubmit}>
          <Paper elevation={6} sx={{ p: 6 }}>
            <Typography variant="h4" sx={{ m: 2, textAlign: 'center', mb: 4 }}>
              Update Report Form
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">Company Name</Typography>
                <TextField
                  size="small"
                  fullWidth
                  value={companyName}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">Company Location</Typography>
                <TextField
                  size="small"
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">Equipment Type</Typography>
                <TextField
                  size="small"
                  fullWidth
                  value={equipment}
                  onChange={(e) => setEquipment(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">Date Started</Typography>
                <TextField
                  size="small"
                  fullWidth
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">Date Completed</Typography>
                <TextField
                  size="small"
                  fullWidth
                  type="date"
                  value={rDate}
                  onChange={(e) => setRDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">Equipment Make</Typography>
                <TextField
                  size="small"
                  fullWidth
                  value={equipmentMake}
                  onChange={(e) => setEquipmentMake(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">Equipment Model</Typography>
                <TextField
                  size="small"
                  fullWidth
                  value={equipmentModel}
                  onChange={(e) => setEquipmentModel(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">Equipment Serial No</Typography>
                <TextField
                  size="small"
                  fullWidth
                  value={equipmentSN}
                  onChange={(e) => setEquipmentSN(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">
                  Inspection Carried Out By
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  value={carried}
                  onChange={(e) => setCarried(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">Fault Reported</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={fault}
                  onChange={(e) => setFault(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">History</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={history}
                  onChange={(e) => setHistory(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">Inspection</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={inspection}
                  onChange={(e) => setInspection(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">Observation</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={observation}
                  onChange={(e) => setObservation(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">Recommendation</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={recommendation}
                  onChange={(e) => setRecommendation(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">Conclusion</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={conclusion}
                  onChange={(e) => setConclusion(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">Spare Part(s) Needed</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={part}
                  onChange={(e) => setPart(e.target.value)}
                />
              </Grid>
            </Grid>
            <Divider textAlign="left" sx={{ m: 3 }}>
              <b>Repairs Carried Out</b>
            </Divider>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">Service Carried Out</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">Repairs Conclusion</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={rConclusion}
                  onChange={(e) => setRConclusion(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">Repairs Recommendation</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={rRecommendation}
                  onChange={(e) => setRRecommendation(e.target.value)}
                />
              </Grid>
            </Grid>
            <Box mt={2} width="100%" display="flex" justifyContent="right">
              {!isPending && (
                <Button variant="contained" type="submit">
                  Update
                </Button>
              )}
              {isPending && (
                <Button variant="outlined">
                  <CircularProgress />
                </Button>
              )}
            </Box>
          </Paper>
          {message && (
            <Modal
              handleClickOpen={handleClickOpen}
              open={open}
              handleClose={handleClose}
              message={message}
              type={'Success'}
            />
          )}
          {isError && (
            <Modal
              handleClickOpen={handleClickOpen}
              open={open}
              handleClose={handleClose}
              message={isError}
              type={'Error'}
            />
          )}
        </Box>
      </Container>
    </Page>
  )
}

export default Update
