// routes
import Router from './routes'
// theme
import ThemeProvider from './theme'
// components
import ScrollToTop from './components/ScrollToTop'
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart'

import { useAuthContext } from './hooks/useAuthContext'

// ----------------------------------------------------------------------

export default function App() {
  const { authIsReady } = useAuthContext()
  return (
    <div>
      {authIsReady && (
        <ThemeProvider>
          <ScrollToTop />
          <BaseOptionChartStyle />
          <Router />
        </ThemeProvider>
      )}
    </div>
  )
}
