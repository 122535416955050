import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Container } from '@mui/system'
import { Typography, Grid } from '@mui/material'

//Hooks
import { useDocument } from '../hooks/useDocument'

const Report = React.forwardRef((props, ref) => {
  const [signature, setSignature] = useState('')
  const [title, setTitle] = useState('')
  const [to, setTo] = useState('')
  const [company, setCompany] = useState('')
  const [address, setAddress] = useState('')
  const [invoice, setInvoice] = useState('')
  const [cpo, setCpo] = useState('')
  const [po, setPo] = useState('')
  const [machine, setMachine] = useState('')
  const [machineMake, setMachineMake] = useState('')
  const [machineModel, setMachineModel] = useState('')
  const [date, setDate] = useState('')
  const [type, setType] = useState('')
  const [rate, setRate] = useState('')
  const [vat, setVat] = useState('')
  const [freight, setFreight] = useState('')
  const [mark, setMark] = useState('')
  const [currency, setCurrency] = useState('')
  const [sign, setSign] = useState('')
  const [position, setPosition] = useState('')
  const [inputDescription, setInputDescription] = useState([
    { description: '', quantity: '', invoice: '', po: '', price: '' },
  ])

  const { id } = useParams()
  const { document } = useDocument('Inventory')
  const { document: data } = useDocument('sign')
  const total = inputDescription
    .map((item) => Number(item.price) * Number(item.quantity))
    .reduce((prev, curr) => prev + curr, 0)

  const vatMain = (vat / 100) * total
  const markMain = (mark / 100) * total
  const subtotal =
    (vatMain ? Number(vatMain) : 0) +
    (total ? Number(total) : 0) +
    (markMain ? Number(markMain) : 0) +
    (freight ? Number(freight) : 0)

  let currencyRate = ''
  if (type === 'dollar2naira' || type === 'euro2naira') {
    currencyRate = `${(subtotal * rate).toFixed(2)} ₦`
  } else if (type === 'pounds2naira') {
    currencyRate = `${(subtotal * rate).toFixed(2)} ₦`
  } else if (type === 'euro2naira') {
    currencyRate = `${(subtotal * rate).toFixed(2)} ₦`
  } else if (type === 'naira2dollar') {
    currencyRate = `${(subtotal / parseInt(rate)).toFixed(2)} $`
  } else if (type === 'naira2pounds') {
    currencyRate = `${(subtotal * rate).toFixed(2)} £`
  } else if (type === 'naira2euro') {
    currencyRate = `${(subtotal * rate).toFixed(2)} €`
  } else if (type === 'dollar2euro') {
    currencyRate = `${(subtotal * rate).toFixed(2)} €`
  } else if (type === 'euro2dollar') {
    currencyRate = `${(subtotal / rate).toFixed(2)} €`
  }

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await document(id)
        const docSnap1 = await data('Signature')
        // console.log(docSnap.data())
        setSignature(docSnap1.data().file)
        setTitle(docSnap.data().title)
        setTo(docSnap.data().to)
        setCompany(docSnap.data().company)
        setAddress(docSnap.data().address)
        setInvoice(docSnap.data().invoice)
        setPo(docSnap.data().po)
        setCpo(docSnap.data().cpo)
        setMachine(docSnap.data().machine)
        setMachineMake(docSnap.data().machineMake)
        setMachineModel(docSnap.data().machineModel)
        setDate(docSnap.data().date)
        setType(docSnap.data().type)
        setRate(docSnap.data().rate)
        setVat(docSnap.data().vat)
        setFreight(docSnap.data().freight)
        setMark(docSnap.data().mark)
        setCurrency(docSnap.data().currency)
        setInputDescription(docSnap.data().inputDescription)
        setSign(docSnap.data().sign)
        setPosition(docSnap.data().position)
      } catch (error) {
        // console.log(error)
      }
    }
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
    }

    // eslint-disable-next-line
  }, [id])

  return (
    <div ref={ref}>
      <Container maxWidth="xl" sx={{ backgroundColor: 'white' }}>
        <Grid container sx={{ mt: 2, mb: 2 }}>
          <Grid item xs={3}>
            <img src="/LOGO.png" alt="logo" />
          </Grid>
          <Grid item xs={8} sx={{ textAlign: 'center' }}>
            <Typography variant="h4">AKOLISA CRANE LIMITED</Typography>
            <Typography variant="body1" sx={{ fontSize: '13px' }}>
              <b>ENGINEERING, SERVICES & CERTIFICATION OF LIFTING EQUIPMENT</b>
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '12px' }}>
              <b>HEAD OFFICE:</b> No. 49 Okwuro water-front off standard rd,
              Elelenwo. PHC
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '12px' }}>
              <b>EMAIL: </b> contact@akolisacranes.com <b>WEBSITE:</b>{' '}
              www.akolisacranes.com
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '12px' }}>
              <b>TEL NO: 084-757807, 084-777926 Mobile No: 08064347013</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid item xs={7}>
            <Typography variant="body2">
              <b>{to}</b>
            </Typography>
            <Typography variant="body2">
              <b>{company}</b>
            </Typography>
            <Typography variant="body2">
              <b>{address}</b>
            </Typography>
            <Typography variant="body2">
              <b>INVOICE NO: {invoice}</b>
            </Typography>
            <Typography variant="body2">
              <b>{date}</b>
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ margin: 'auto', mr: 0 }}>
            <Typography
              variant="body2"
              sx={{ display: 'flex', justifyContent: 'right' }}
            >
              <b>PO Number</b> {` : ${po}`}
            </Typography>
            <Typography
              variant="body2"
              sx={{ display: 'flex', justifyContent: 'right' }}
            >
              <b>Customer's PO Number</b> {` : ${cpo}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid sx={{ m: 2 }}>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            {title}
          </Typography>
        </Grid>
        <Grid>
          <Typography variant="body2">
            <b>MACHINE TYPE: {machine}</b>
          </Typography>
          <Typography variant="body2">
            <b>MACHINE MAKE: {machineMake}</b>
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            <b>MACHINE MODEL: {machineModel}</b>
          </Typography>
        </Grid>
        <Grid sx={{ border: '1px solid' }}>
          <Grid container>
            <Grid item xs={1} sx={{ border: '1px solid', pt: 2 }}>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                <b>S/N</b>
              </Typography>
            </Grid>
            <Grid item xs={5} sx={{ border: '1px solid', pt: 2 }}>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                <b>DESCRIPTION</b>
              </Typography>
            </Grid>
            <Grid item xs={1} sx={{ border: '1px solid', pt: 2 }}>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                <b>QTY</b>
              </Typography>
            </Grid>

            <Grid item xs={2.5} sx={{ border: '1px solid', pt: 2 }}>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                <b>PRICE {currency}</b>
              </Typography>
            </Grid>
            <Grid item xs={2.5} sx={{ border: '1px solid', pt: 2 }}>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                <b>TOTAL {currency}</b>
              </Typography>
            </Grid>
          </Grid>
          {inputDescription.map((desc, i) => {
            return (
              <Grid container key={i}>
                <Grid item xs={1} sx={{ border: '1px solid' }}>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    {i + 1}
                  </Typography>
                </Grid>
                <Grid item xs={5} sx={{ border: '1px solid' }}>
                  <Typography variant="body1" sx={{ pl: 2 }}>
                    {desc.description}
                  </Typography>
                </Grid>
                <Grid item xs={1} sx={{ border: '1px solid' }}>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    {desc.quantity}
                  </Typography>
                </Grid>
                <Grid item xs={2.5} sx={{ border: '1px solid' }}>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    {Number(desc.price)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </Typography>
                </Grid>
                <Grid item xs={2.5} sx={{ border: '1px solid' }}>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    {Number(desc.price * desc.quantity)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </Typography>
                </Grid>
              </Grid>
            )
          })}
          <Grid sx={{ p: 3, border: '1px solid' }} />
          <Grid container>
            {rate && (
              <Grid container>
                <Grid item xs={9.5} sx={{ border: '1px solid' }}>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: 'right', pr: 2 }}
                  >
                    EXCHANGE RATE
                  </Typography>
                </Grid>
                <Grid item xs={2.5} sx={{ border: '1px solid' }}>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    {currencyRate.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid item xs={9.5} sx={{ border: '1px solid' }}>
              <Typography variant="body1" sx={{ textAlign: 'right', pr: 2 }}>
                TOTAL AMOUNT
              </Typography>
            </Grid>
            <Grid item xs={2.5} sx={{ border: '1px solid' }}>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                {total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
              </Typography>
            </Grid>
            <Grid item xs={9.5} sx={{ border: '1px solid' }}>
              <Typography variant="body1" sx={{ textAlign: 'right', pr: 2 }}>
                {`${mark}% MARKUP`}
              </Typography>
            </Grid>
            <Grid item xs={2.5} sx={{ border: '1px solid' }}>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                {markMain.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
              </Typography>
            </Grid>
            {freight && (
              <Grid container>
                <Grid item xs={9.5} sx={{ border: '1px solid' }}>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: 'right', pr: 2 }}
                  >
                    FREIGHT
                  </Typography>
                </Grid>
                <Grid item xs={2.5} sx={{ border: '1px solid' }}>
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    {Number(freight)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Grid item xs={9.5} sx={{ border: '1px solid' }}>
              <Typography variant="body1" sx={{ textAlign: 'right', pr: 2 }}>
                {`VAT (${vat}%) `}
              </Typography>
            </Grid>
            <Grid item xs={2.5} sx={{ border: '1px solid' }}>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                {vatMain.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
              </Typography>
            </Grid>
            <Grid item xs={9.5} sx={{ border: '1px solid' }}>
              <Typography variant="body1" sx={{ textAlign: 'right', pr: 2 }}>
                SUB-TOTAL AMOUNT
              </Typography>
            </Grid>
            <Grid item xs={2.5} sx={{ border: '1px solid' }}>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                {subtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 3, mb: 2, justifyContent: 'space-between' }}>
          <Grid item xs={5.8} sx={{ p: 1 }}>
            <Typography variant="body1">
              <b>SIGNED: </b>{' '}
            </Typography>
            <Typography variant="body2">
              <b>{sign}</b>
            </Typography>
            <Typography variant="body2">
              <b>{position}</b>
            </Typography>
            <img src={signature} alt="signature" style={{ width: '120px' }} />
          </Grid>
          <Grid item xs={6.2}>
            <Typography variant="body2">
              <b>BANK NAME:</b> ACCESS BANK PLC
            </Typography>
            <Typography variant="body2">
              <b>ACCOUNT NAME:</b> AKOLISA CRANE LIMITED
            </Typography>
            <Typography variant="body2">
              <b>USD ($) ACCOUNT NUMBER:</b> 0001256732
            </Typography>
            <Typography variant="body2">
              <b>NAIRA (₦)ACCOUNT NUMBER:</b> 0001219955
            </Typography>
            <Typography variant="body2">
              <b>TAX IDENTIFICATION NO:</b> 04134191
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 3 }}>
          {/* <Grid item xs={5} sx={{ color: 'tomato' }}>
            <Typography variant="body2">
              COMPANY NAME: AKOLISA CRANE LTD
            </Typography>
            <Typography variant="body2">
              TAX IDENTIFICATION NO: 04134191
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <Typography
              variant="body1"
              sx={{ borderBottom: '1px solid', mr: 3 }}
            >
              <b>USD ($) BANK PAYMENT DETAILS</b>
            </Typography>
            <Typography variant="body2">
              ACCOUNT NAME: AKOLISA CRANE LIMITED
            </Typography>
            <Typography variant="body2">ACCOUNT NUMBER: 0001256732</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ backgroundColor: 'green', color: 'white', pl: 2, mt: 2 }}
          >
            <Typography variant="body1" sx={{ borderBottom: '1px solid' }}>
              <b>NAIRA (₦) BANK PAYMENT DETAILS</b>
            </Typography>
            <Typography variant="body2">
              ACCOUNT NAME: AKOLISA CRANE LIMITED
            </Typography>
            <Typography variant="body2">ACCOUNT NUMBER: 0001219955</Typography>
          </Grid> */}
        </Grid>
      </Container>
    </div>
  )
})

export default Report
