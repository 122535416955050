import { useState } from 'react'
import { Link } from 'react-router-dom'

import {
  // Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Box,
  Alert,
  CircularProgress,
} from '@mui/material'

// components
import Iconify from '../../../components/Iconify'

//Hooks
import { useLogin } from '../../../hooks/useLogin'

// ----------------------------------------------------------------------

export default function LoginForm() {
  // const navigate = useNavigate()
  const { login, isPending, message, error } = useLogin()

  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log(email, password)
    login(email, password)
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box sx={{ mb: 4 }}>
        {message && (
          <Alert variant="filled" severity="success">
            {message}
          </Alert>
        )}
        {error && (
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        )}
      </Box>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          name="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Link variant="subtitle2" to="/forgot-password" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      {!isPending && (
        <Button fullWidth size="large" type="submit" variant="contained">
          Login
        </Button>
      )}
      {isPending && (
        <Button fullWidth size="large" type="submit" variant="outlined">
          <CircularProgress />
        </Button>
      )}
    </Box>
  )
}
