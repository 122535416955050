import { useState } from 'react'
// import { useNavigate } from 'react-router-dom';

// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Box,
  TextField,
  Button,
  MenuItem,
  Grid,
  Alert,
  CircularProgress,
} from '@mui/material'

// components
import Iconify from '../../../components/Iconify'

//Hooks
import { useSignup } from '../../../hooks/useSignup'

// ----------------------------------------------------------------------

export default function RegisterForm() {
  // const navigate = useNavigate();
  const { signup, error, isPending, success } = useSignup()

  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [photoURL, setPhotoURL] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    signup(email, password, displayName, photoURL)
    setEmail('')
    setPassword('')
    setDisplayName('')
    setPhotoURL('')
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box sx={{ mb: 4 }}>
        {success && (
          <Alert variant="filled" severity="success">
            {success}
          </Alert>
        )}
        {error && (
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        )}
      </Box>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Grid container>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                value={displayName}
                label="Names"
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                id="select"
                select
                fullWidth
                required
                value={photoURL}
                onChange={(e) => setPhotoURL(e.target.value)}
              >
                <MenuItem value="Staff">Staff</MenuItem>
                <MenuItem value="Account">Account</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Stack>

        <TextField
          value={email}
          label="Email address"
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {!isPending && (
          <Button fullWidth size="large" type="submit" variant="contained">
            Register
          </Button>
        )}
        {isPending && (
          <Button fullWidth size="large" variant="outlined">
            <CircularProgress />
          </Button>
        )}
      </Stack>
    </Box>
  )
}
