import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import DeleteIcon from '@mui/icons-material/Delete'

//Hooks
import { useFirestore } from '../hooks/useFirestore'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function DeleteModal({ id, data, ...others }) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const { deleteDocument } = useFirestore(data)

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = () => {
    deleteDocument(id)
    setOpen(false)
  }

  return (
    <div>
      <button
        style={{
          border: 'none',
          background: 'transparent',
          cursor: 'pointer',
          marginLeft: '2px',
          color: 'red',
        }}
        variant="outlined"
        onClick={handleClickOpen}
      >
        <DeleteIcon />
      </button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={'xs'}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="error">{'Are You Sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You want to delete
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            No
          </Button>
          <Button variant="contained" color="error" onClick={handleDelete}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
