import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Container } from '@mui/system'
import {
  Typography,
  Paper,
  Box,
  TextField,
  Grid,
  Button,
  CircularProgress,
  Alert,
  IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import React from 'react'
import Page from '../components/Page'

//Hooks
import { useFirestore } from '../hooks/useFirestore'
import { useDocument } from '../hooks/useDocument'

function AddCompany() {
  const navigate = useNavigate()
  const { id } = useParams()
  const { document } = useDocument('Company')

  const { addDocument, message, isError, isPending, updateDocument } =
    useFirestore('Company')
  const [companyName, setCompanyName] = useState('')
  const [inputBranch, setInputBranch] = useState([{ branch: '' }])

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await document(id)
        // console.log(docSnap.data())
        setCompanyName(docSnap.data().companyName)
        setInputBranch(docSnap.data().inputBranch)
      } catch (error) {
        console.log(error)
      }
    }
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
    }

    // eslint-disable-next-line
  }, [id])

  const handleSubmit = async (e) => {
    e.preventDefault()
    // addDocument({ companyName, inputBranch })
    try {
      if ((id !== undefined || id !== '') && id !== ':id') {
        await updateDocument(id, { companyName, inputBranch })
      } else {
        await addDocument({ companyName, inputBranch })
      }
    } catch (error) {
      // console.log('msg', error.message)
    }
  }

  const handleFormChange = (i, e) => {
    let data = [...inputBranch]
    data[i][e.target.name] = e.target.value
    setInputBranch(data)
  }

  const addField = () => {
    let newField = { branch: '' }
    setInputBranch([...inputBranch, newField])
  }

  const removeField = (i) => {
    let data = [...inputBranch]
    data.splice(i, 1)
    setInputBranch(data)
  }

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        navigate('/dashboard/app')
      }, 5000)
    }
  })

  return (
    <Page title="Add Company">
      <Container maxWidth="xl">
        <Box component="form" onSubmit={handleSubmit}>
          <Paper elevation={6} sx={{ p: 4 }}>
            <Typography variant="h4" sx={{ m: 1, textAlign: 'center' }}>
              Add Company
            </Typography>
            <Box sx={{ mb: 4 }}>
              {message && (
                <Alert variant="filled" severity="success">
                  {message}
                </Alert>
              )}
              {isError && (
                <Alert variant="filled" severity="error">
                  {isError}
                </Alert>
              )}
            </Box>
            <Grid
              container
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 4,
              }}
            >
              <Grid item xs={12} md={7} sm={7}>
                <TextField
                  fullWidth
                  placeholder="Enter Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  disabled={
                    (id !== undefined || id !== '') && id !== ':id'
                      ? true
                      : false
                  }
                />
              </Grid>
            </Grid>

            {inputBranch.map((input, i) => {
              return (
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 2,
                  }}
                  key={i}
                >
                  <Grid item xs={10} md={6} sm={6}>
                    <TextField
                      fullWidth
                      placeholder="Enter Location"
                      name="branch"
                      value={input.branch}
                      onChange={(e) => handleFormChange(i, e)}
                    />
                  </Grid>
                  <Grid item xs={2} md={1} sm={1}>
                    <IconButton color="error" onClick={() => removeField(i)}>
                      <DeleteIcon />
                    </IconButton>{' '}
                  </Grid>
                </Grid>
              )
            })}
            <Grid
              container
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <Button
                  size="medium"
                  variant="contained"
                  onClick={addField}
                  //   color="success"
                  sx={{
                    backgroundColor: '#ee82ee',
                    '&:hover': {
                      backgroundColor: '#ee82ee',
                      boxShadow: 'none',
                    },
                  }}
                >
                  Add Branch
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 4,
              }}
            >
              <Grid item xs={12} md={7} sm={7} sx={{ mb: 5 }}>
                {!isPending && (
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                )}
                {isPending && (
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="outlined"
                  >
                    <CircularProgress />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </Page>
  )
}

export default AddCompany
