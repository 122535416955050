// import { db } from '../firebase'
import { appFirestore, appStorage } from '../firebase/config'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'

import {
  collection,
  // getDoc,
  addDoc,
  updateDoc,
  // deleteDoc,
  doc,
} from 'firebase/firestore'

const staffCollectionRef = collection(appFirestore, 'c')

class KingsooService {
  addStaff = (newStaff) => {
    return addDoc(staffCollectionRef, newStaff)
  }

  addSign = (id, { file }) => {
    const img = ref(appStorage, `${id}/${'akolisa'}`)
    uploadBytes(img, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const staffDoc = doc(appFirestore, 'sign', id)
        return updateDoc(staffDoc, {
          file: url,
        })
      })
    })
  }
}

export default new KingsooService()
