import { Container } from '@mui/system'
import { useState, useEffect } from 'react'
import {
  Paper,
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
} from '@mui/material'
import Page from '../components/Page'
import Modal from '../components/Modal'
import { useParams, useNavigate } from 'react-router'

//Hooks
import { useDocument } from '../hooks/useDocument'
import { useFirestore } from '../hooks/useFirestore'
import { useAuthContext } from '../hooks/useAuthContext'

function Inspection() {
  const [companyName, setCompanyName] = useState('New')
  const [inputBranch, setInputBranch] = useState([])
  const [branch, setBranch] = useState('')
  const [fault, setFault] = useState('')
  const [inspection, setInspection] = useState()
  const [recommendation, setRecommendation] = useState('')
  const [conclusion, setConclusion] = useState('')
  const [equipment, setEquipment] = useState('')
  const [equipmentModel, setEquipmentModel] = useState('')
  const [equipmentMake, setEquipmentMake] = useState('')
  const [equipmentSN, setEquipmentSN] = useState('')
  const [date, setDate] = useState('')
  const [service] = useState('')
  const [rConclusion] = useState('')
  const [rRecommendation] = useState('')
  const [rDate] = useState('')
  const [history, setHistory] = useState('')
  const [observation, setObservation] = useState('')
  const [part, setPart] = useState('')
  const [carried, setCarried] = useState('')

  const { id } = useParams()
  const navigate = useNavigate()
  const { document } = useDocument('Company')
  const { addDocument, message, isError, isPending } = useFirestore(companyName)
  const { user } = useAuthContext()

  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await document(id)
        // console.log(docSnap.data())
        setCompanyName(docSnap.data().companyName)
        setInputBranch(docSnap.data().inputBranch)
      } catch (error) {
        console.log(error)
      }
    }
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
    }

    // eslint-disable-next-line
  }, [id])

  const handleSubmit = (e) => {
    e.preventDefault()
    const name = user.displayName
    const inspect = {
      companyName,
      branch,
      fault,
      inspection,
      recommendation,
      conclusion,
      name,
      date,
      equipment,
      equipmentModel,
      equipmentMake,
      equipmentSN,
      service,
      rConclusion,
      rRecommendation,
      rDate,
      history,
      observation,
      part,
      carried,
    }
    addDocument(inspect)
  }

  useEffect(() => {
    if (message) {
      setOpen(true)
      setTimeout(() => {
        navigate(`/dashboard/company/${id}`, {
          replace: true,
        })
      }, 2000)
    }
  }, [message, navigate, id])

  return (
    <Page title="Inspection">
      <Container maxWidth="xl">
        <Box component="form" onSubmit={handleSubmit}>
          <Paper elevation={6} sx={{ p: 6 }}>
            <Typography variant="h4" sx={{ m: 2, textAlign: 'center', mb: 4 }}>
              Inspection Form
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">Company Name</Typography>
                <TextField
                  size="small"
                  fullWidth
                  value={companyName}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">Company Location</Typography>
                <TextField
                  id="select"
                  size="small"
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                  select
                  fullWidth
                  required
                >
                  {inputBranch.map(({ branch }) => {
                    return (
                      <MenuItem value={branch} key={branch}>
                        {branch}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">Equipment Type</Typography>
                <TextField
                  size="small"
                  fullWidth
                  value={equipment}
                  onChange={(e) => setEquipment(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">Date Started</Typography>
                <TextField
                  size="small"
                  fullWidth
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">Equipment Make</Typography>
                <TextField
                  size="small"
                  fullWidth
                  value={equipmentMake}
                  onChange={(e) => setEquipmentMake(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">Equipment Model</Typography>
                <TextField
                  size="small"
                  fullWidth
                  value={equipmentModel}
                  onChange={(e) => setEquipmentModel(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <Typography variant="body2">Equipment Serial No</Typography>
                <TextField
                  size="small"
                  fullWidth
                  value={equipmentSN}
                  onChange={(e) => setEquipmentSN(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={8} xl={8}>
                <Typography variant="body2">
                  Inspection Carried out by:
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  value={carried}
                  onChange={(e) => setCarried(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">Fault Reported</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={fault}
                  onChange={(e) => setFault(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">History</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={history}
                  onChange={(e) => setHistory(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">Inspection</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={inspection}
                  onChange={(e) => setInspection(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">Observation</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={observation}
                  onChange={(e) => setObservation(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">Recommendation</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={recommendation}
                  onChange={(e) => setRecommendation(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">Conclusion</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={conclusion}
                  onChange={(e) => setConclusion(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Typography variant="body2">Equipment Part Needed</Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  size="small"
                  value={part}
                  onChange={(e) => setPart(e.target.value)}
                />
              </Grid>
            </Grid>
            <Box mt={2} width="100%" display="flex" justifyContent="right">
              {!isPending && (
                <Button variant="contained" type="submit">
                  Submit
                </Button>
              )}
              {isPending && (
                <Button variant="outlined">
                  <CircularProgress />
                </Button>
              )}
            </Box>
          </Paper>
          {message && (
            <Modal
              handleClickOpen={handleClickOpen}
              open={open}
              handleClose={handleClose}
              message={message}
              type={'Success'}
            />
          )}
          {isError && (
            <Modal
              handleClickOpen={handleClickOpen}
              open={open}
              handleClose={handleClose}
              message={isError}
              type={'Error'}
            />
          )}
        </Box>
      </Container>
    </Page>
  )
}

export default Inspection
