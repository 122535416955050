import { Container } from '@mui/system'
import {
  Typography,
  Paper,
  Grid,
  //   MenuItem,
  //   TextField,
  Button,
} from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Page from '../components/Page'
import Table from '../components/Table'

//Hooks
import { useDocument } from '../hooks/useDocument'
import { useCollection } from '../hooks/useCollection'

function Company() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { document } = useDocument('Company')

  const [companyName, setCompanyName] = useState('Bad Network')
  //   const [inputBranch, setInputBranch] = useState([])
  //   const [branch, setBranch] = useState('')

  const { documents } = useCollection(companyName)

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await document(id)
        // console.log(docSnap.data())
        setCompanyName(docSnap.data().companyName)
        // setInputBranch(docSnap.data().inputBranch)
      } catch (error) {
        console.log(error)
      }
    }
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
    }

    // eslint-disable-next-line
  }, [id])

  return (
    <Page title={companyName}>
      <Container maxWidth="xl">
        <Paper elevation={6} sx={{ p: 4 }}>
          <Grid
            container
            spacing={3}
            sx={{ justifyContent: 'space-between', marginBottom: 1 }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="h4" sx={{ mb: 1 }}>
                {companyName}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            sx={{ justifyContent: 'space-between', marginBottom: 1, mt: 1 }}
          >
            <Grid item xs={12} sm={3} md={3}>
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate(`/dashboard/inspection/${id}`)}
              >
                Create Report
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Button
                variant="contained"
                size="large"
                color="warning"
                onClick={() => navigate(`/dashboard/add-company/${id}`)}
              >
                Edit Company
              </Button>
            </Grid>
          </Grid>
          <Table data={documents} />
        </Paper>
      </Container>
    </Page>
  )
}

export default Company
