import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
// import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Box } from '@mui/system'
import kingsooServices from '../hooks/kingsooServices'

export default function AlertDialog() {
  const [open, setOpen] = React.useState(false)
  const [file, setFile] = React.useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log('file', file)
    const id = 'Signature'
    try {
      await kingsooServices.addSign(id, { file })
      setOpen(false)
    } catch (error) {
      console.log(error.message)
    }
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Upload Signature
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle id="alert-dialog-title">
            {'Upload Signature For All Inventory'}
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Button variant="contained" component="label" sx={{ m: 3 }}>
                Upload Signature
                <input
                  type="file"
                  hidden
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Button>
              <Button type="submit" variant="outlined" sx={{ m: 3 }}>
                Submit
              </Button>
            </DialogContentText>
          </DialogContent>
          {/* <DialogActions sx={{ justifyContent: 'center' }}>
          <Button type="submit" autoFocus onClick={handleClose}>
            Submit
          </Button>
        </DialogActions> */}
        </Box>
      </Dialog>
    </div>
  )
}
