import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Container } from '@mui/system'
import { Typography, Grid } from '@mui/material'

//Hooks
import { useDocument } from '../hooks/useDocument'

const Report = React.forwardRef((props, ref) => {
  const [companyName, setCompanyName] = useState('')
  const [branch, setBranch] = useState('')
  const [fault, setFault] = useState('')
  const [equipment, setEquipment] = useState('')
  const [equipmentModel, setEquipmentModel] = useState('')
  const [equipmentMake, setEquipmentMake] = useState('')
  const [equipmentSN, setEquipmentSN] = useState('')
  const [date, setDate] = useState('')
  const [inspection, setInspection] = useState('')
  const [conclusion, setConclusion] = useState('')
  const [recommendation, setRecommendation] = useState('')
  // const [history, setHistory] = useState('')
  const [observation, setObservation] = useState('')
  const [part, setPart] = useState('')

  const { id, company } = useParams()
  const { document } = useDocument(company)

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await document(id)
        // console.log(docSnap.data())
        setCompanyName(docSnap.data().companyName)
        setBranch(docSnap.data().branch)
        setFault(docSnap.data().fault)
        setEquipment(docSnap.data().equipment)
        setEquipmentModel(docSnap.data().equipmentModel)
        setEquipmentMake(docSnap.data().equipmentMake)
        setEquipmentSN(docSnap.data().equipmentSN)
        setDate(docSnap.data().date)
        setInspection(docSnap.data().inspection)
        setConclusion(docSnap.data().conclusion)
        setRecommendation(docSnap.data().recommendation)
        // setHistory(docSnap.data().history)
        setObservation(docSnap.data().observation)
        setPart(docSnap.data().part)
      } catch (error) {
        console.log(error)
      }
    }
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
    }

    // eslint-disable-next-line
  }, [id])

  return (
    <div className="bg-gray-200 p-6" ref={ref}>
      <Container maxWidth="xl" sx={{ backgroundColor: 'white' }}>
        <Grid container sx={{ mt: 2, mb: 2 }}>
          <Grid item xs={3}>
            <img src="/LOGO.png" alt="logo" />
          </Grid>
          <Grid item xs={8} sx={{ textAlign: 'center' }}>
            <Typography variant="h4">AKOLISA CRANE LIMITED</Typography>
            <Typography variant="body1" sx={{ fontSize: '13px' }}>
              <b>ENGINEERING, SERVICES & CERTIFICATION OF LIFTING EQUIPMENT</b>
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '12px' }}>
              <b>HEAD OFFICE:</b> No. 49 Okwuro water-front off standard rd,
              Elelenwo. PHC
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '12px' }}>
              <b>EMAIL: </b> contact@akolisacranes.com <b>WEBSITE:</b>{' '}
              www.akolisacranes.com
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '12px' }}>
              <b>TEL NO: 084-757807, 084-777926 Mobile No: 08064347013</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid sx={{ textAlign: 'center' }}>
          <Typography variant="h4">INSPECTION REPORT</Typography>
        </Grid>
        <Grid component="div" container sx={{ border: '1px solid' }}>
          <Grid
            item
            xs={7}
            sx={{ display: 'flex', border: '1px solid', padding: 1 }}
            component="div"
          >
            <Typography variant="body" sx={{ margin: 0.5 }}>
              <b>Company Name: </b>{' '}
            </Typography>
            <Typography variant="body" sx={{ margin: 0.5 }}>
              {' '}
              {companyName}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{ display: 'flex', border: '1px solid', padding: 1 }}
            component="div"
          >
            <Typography variant="body" sx={{ margin: 0.5 }}>
              <b>Location: </b>{' '}
            </Typography>
            <Typography variant="body" sx={{ margin: 0.5 }}>
              {' '}
              {branch}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: 'flex', border: '1px solid', padding: 1 }}
            component="div"
          >
            <Typography variant="body1" sx={{ margin: 0.5 }}>
              <b>Equipment Type: </b>{' '}
            </Typography>
            <Typography variant="body1" sx={{ margin: 0.5 }}>
              {' '}
              {equipment}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: 'flex', border: '1px solid', padding: 1 }}
            component="div"
          >
            <Typography variant="body1" sx={{ margin: 0.5 }}>
              <b>Date : </b>{' '}
            </Typography>
            <Typography variant="body1" sx={{ margin: 0.5 }}>
              {' '}
              {date}
            </Typography>
          </Grid>

          <Grid
            item
            xs={4}
            sx={{ display: 'flex', border: '1px solid', padding: 1 }}
            component="div"
          >
            <Typography variant="body1" sx={{ margin: 0.5 }}>
              <b>Equipment Make: </b>{' '}
            </Typography>
            <Typography variant="body1" sx={{ margin: 0.5 }}>
              {' '}
              {equipmentMake}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', border: '1px solid', padding: 1 }}
            component="div"
          >
            <Typography variant="body1" sx={{ margin: 0.5 }}>
              <b>Equipment Model: </b>{' '}
            </Typography>
            <Typography variant="body1" sx={{ margin: 0.5 }}>
              {' '}
              {equipmentModel}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', border: '1px solid', padding: 1 }}
            component="div"
          >
            <Typography variant="body1" sx={{ margin: 0.5 }}>
              <b>Equipment Serial No: </b>{' '}
            </Typography>
            <Typography variant="body1" sx={{ margin: 0.5 }}>
              {' '}
              {equipmentSN}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              border: '1px solid',
              padding: 1.5,
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Typography variant="body" sx={{ margin: 1, color: '#2065d1' }}>
              <b>Brief Summary Of Report </b>
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ border: '1px solid', padding: 1 }}>
            <Typography variant="body" sx={{ margin: 1 }}>
              <b>Fault Reported: </b>{' '}
            </Typography>
            <Typography variant="body" sx={{ margin: 1 }}>
              {' '}
              {fault}
            </Typography>
          </Grid>
          {/* <Grid item xs={6} sx={{ border: '1px solid', padding: 1 }}>
            <Typography variant="body" sx={{ margin: 1 }}>
              <b>History: </b>{' '}
            </Typography>
            <Typography variant="body" sx={{ margin: 1 }}>
              {' '}
              {history}
            </Typography>
          </Grid> */}
          <Grid item xs={6} sx={{ border: '1px solid', padding: 1 }}>
            <Typography variant="body" sx={{ margin: 1 }}>
              <b>Inspection: </b>{' '}
            </Typography>
            <Typography variant="body" sx={{ margin: 1 }}>
              {' '}
              {inspection}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ border: '1px solid', padding: 1 }}>
            <Typography variant="body" sx={{ margin: 1 }}>
              <b>Observation: </b>{' '}
            </Typography>
            <Typography variant="body" sx={{ margin: 1 }}>
              {' '}
              {observation}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ border: '1px solid', padding: 1 }}>
            <Typography variant="body" sx={{ margin: 1 }}>
              <b>Part Needed: </b>{' '}
            </Typography>
            <Typography variant="body" sx={{ margin: 1 }}>
              {' '}
              {part}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ border: '1px solid', padding: 1 }}>
            <Typography variant="body" sx={{ margin: 1 }}>
              <b>Recommendation: </b>{' '}
            </Typography>
            <Typography variant="body" sx={{ margin: 1 }}>
              {' '}
              {recommendation}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ border: '1px solid', padding: 1 }}>
            <Typography variant="body" sx={{ margin: 1 }}>
              <b>Conclusion: </b>{' '}
            </Typography>
            <Typography variant="body" sx={{ margin: 1 }}>
              {' '}
              {conclusion}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2, justifyContent: 'space-between' }}>
          <Grid item xs={5.8} sx={{ border: '1px ridge', p: 1 }}>
            <Typography variant="body1" sx={{ margin: 0.5, mb: 5 }}>
              <b>Service Engineer: </b>{' '}
            </Typography>
            <Typography variant="body1" sx={{ margin: 0.5, mt: 2 }}>
              <b>SIGN / DATE:</b>
            </Typography>
          </Grid>
          <Grid item xs={5.8} sx={{ border: '1px ridge', p: 1 }}>
            <Typography variant="body1" sx={{ margin: 0.5, mb: 5 }}>
              <b>Company Representative Client: </b>{' '}
            </Typography>
            <Typography variant="body1" sx={{ margin: 0.5, mt: 2 }}>
              <b>SIGN / DATE:</b>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
})

export default Report
