import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function AlertDialogSlide({
  handleClickOpen,
  handleClose,
  open,
  type,
  message,
}) {
  const colors = type === 'Success' ? 'green' : 'red'

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={'xs'}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{ backgroundColor: 'none' }}
      >
        <DialogTitle sx={{ color: colors }}>
          {type === 'Success' ? (
            <CheckCircleIcon size="medium" />
          ) : (
            <CancelIcon size="small" />
          )}{' '}
          {type}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{ color: '#fff', mt: 2 }}
          >
            Continue
          </Button>
          {/* <Button onClick={handleClose}>Continue</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  )
}
