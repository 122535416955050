import { Grid, Container, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
// components
import Page from '../components/Page'
import Upload from '../components/Upload'

import { AppWidgetSummary } from '../sections/@dashboard/app'

//Hooks
import { useCollection } from '../hooks/useCollection'

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const navigate = useNavigate()
  const { documents } = useCollection('Inventory')
  const { documents: data } = useCollection('Service')

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Hi, Welcome back
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Upload />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6} sm={4} md={3}>
            <AppWidgetSummary
              title={`${documents.length} Document(s)`}
              name={'Invoice & quotation'}
              icon={'la:file-invoice-dollar'}
              onClick={() => navigate(`/dashboard/inventory-invoice`)}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <AppWidgetSummary
              title={`${data.length} Document(s)`}
              name={'Service Invoice'}
              icon={'la:file-invoice-dollar'}
              onClick={() => navigate(`/dashboard/inventory-service`)}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}
