import { Grid, Container, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
// components
import Page from '../components/Page'

import { AppWidgetSummary } from '../sections/@dashboard/app'

//Hooks
import { useCollection } from '../hooks/useCollection'

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const navigate = useNavigate()
  const { documents } = useCollection('Company')

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          {documents.map((doc) => {
            return (
              <Grid item xs={6} sm={4} md={3} key={doc.id}>
                <AppWidgetSummary
                  title={`${doc.inputBranch.length} Location`}
                  name={doc.companyName}
                  icon={'mdi:office-building'}
                  onClick={() => navigate(`/dashboard/company/${doc.id}`)}
                />
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </Page>
  )
}
