import { useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Stack,
  TextField,
  Button,
  Box,
  Alert,
  CircularProgress,
} from '@mui/material'

// components
// import Iconify from '../../../components/Iconify'

//Hooks
import { useLogin } from '../../../hooks/useLogin'

// ----------------------------------------------------------------------

export default function ForgotPassword() {
  // const navigate = useNavigate()
  const { forgotPassword, isPending, message, error } = useLogin()

  // const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState('')
  // const [password, setPassword] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log(email, password)
    forgotPassword(email)
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box sx={{ mb: 4 }}>
        {message && (
          <Alert variant="filled" severity="success">
            {message}
          </Alert>
        )}
        {error && (
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        )}
      </Box>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Link variant="subtitle2" to="/login" underline="hover">
          Back to Login?
        </Link>
      </Stack>

      {!isPending && (
        <Button fullWidth size="large" type="submit" variant="contained">
          Login
        </Button>
      )}
      {isPending && (
        <Button fullWidth size="large" type="submit" variant="outlined">
          <CircularProgress />
        </Button>
      )}
    </Box>
  )
}
