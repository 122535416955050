import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container } from '@mui/system'
import {
  Paper,
  Grid,
  Typography,
  TextField,
  MenuItem,
  IconButton,
  Button,
  CircularProgress,
  Box,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
import Page from '../components/Page'
import Modal from '../components/Modal'

//Hooks
import { useFirestore } from '../hooks/useFirestore'
import { useDocument } from '../hooks/useDocument'

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      suffix="%"
    />
  )
})

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const NumberFormatCustom1 = React.forwardRef(function NumberFormatCustom1(
  props,
  ref
) {
  const { onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      //   suffix="%"
    />
  )
})

NumberFormatCustom1.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

function Form() {
  const [title, setTitle] = useState('')
  const [to, setTo] = useState('')
  const [company, setCompany] = useState('')
  const [address, setAddress] = useState('')
  const [invoice, setInvoice] = useState('')
  const [cpo, setCpo] = useState('')
  const [po, setPo] = useState('')
  const [machine, setMachine] = useState('')
  const [machineMake, setMachineMake] = useState('')
  const [machineModel, setMachineModel] = useState('')
  const [type, setType] = useState('')
  const [rate, setRate] = useState('')
  const [vat, setVat] = useState('')
  const [freight, setFreight] = useState('')
  const [mark, setMark] = useState('')
  const [date, setDate] = useState('')
  const [currency, setCurrency] = useState('')
  const [sign, setSign] = useState('')
  const [position, setPosition] = useState('')
  const [inputDescription, setInputDescription] = useState([
    {
      description: '',
      quantity: '',
      //  invoice: '', po: '',
      price: '',
    },
  ])

  const { document } = useDocument('Inventory')
  const { addDocument, updateDocument, message, isError, isPending } =
    useFirestore('Inventory')
  const { id } = useParams()
  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await document(id)
        // console.log(docSnap.data())
        setTitle(docSnap.data().title)
        setTo(docSnap.data().to)
        setCompany(docSnap.data().company)
        setAddress(docSnap.data().address)
        setInvoice(docSnap.data().invoice)
        setPo(docSnap.data().po)
        setCpo(docSnap.data().cpo)
        setMachine(docSnap.data().machine)
        setMachineMake(docSnap.data().machineMake)
        setMachineModel(docSnap.data().machineModel)
        setDate(docSnap.data().date)
        setType(docSnap.data().type)
        setRate(docSnap.data().rate)
        setVat(docSnap.data().vat)
        setMark(docSnap.data().mark)
        setFreight(docSnap.data().freight)
        setCurrency(docSnap.data().currency)
        setInputDescription(docSnap.data().inputDescription)
        setSign(docSnap.data().sign)
        setPosition(docSnap.data().position)
      } catch (error) {
        // console.log(error)
      }
    }
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
    }

    // eslint-disable-next-line
  }, [id])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const inventory = {
      title,
      to,
      company,
      address,
      invoice,
      date,
      cpo,
      po,
      machine,
      machineMake,
      machineModel,
      type,
      rate,
      vat,
      mark,
      currency,
      inputDescription,
      freight,
      sign,
      position,
    }
    try {
      if ((id !== undefined || id !== '') && id !== ':id') {
        await updateDocument(id, inventory)
      } else {
        await addDocument(inventory)
      }
    } catch (error) {
      // console.log('msg', error.message)
    }
  }
  const navigate = useNavigate()
  useEffect(() => {
    if (message) {
      setOpen(true)
      setTimeout(() => {
        navigate('/dashboard/inventory', {
          replace: true,
        })
      }, 5000)
    }
  }, [message, navigate])

  const handleFormChange = (i, e) => {
    let data = [...inputDescription]
    data[i][e.target.name] = e.target.value
    setInputDescription(data)
  }

  const addField = () => {
    let newField = {
      description: '',
      quantity: '',
      //   invoice: '',
      //   po: '',
      price: '',
    }
    setInputDescription([...inputDescription, newField])
  }

  const removeField = (i) => {
    let data = [...inputDescription]
    data.splice(i, 1)
    setInputDescription(data)
  }
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Page title="Form">
      <Container maxWidth="xl">
        <Box component="form" onSubmit={handleSubmit}>
          <Paper elevation={6} sx={{ p: 6 }}>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between', marginBottom: 1 }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="h4" sx={{ mb: 1 }}>
                  Inventory Form
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="body2">Inventory Title</Typography>
                <TextField
                  size="small"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="body2">Address To:</Typography>
                <TextField
                  size="small"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="body2">Company Name</Typography>
                <TextField
                  size="small"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="body2">Address</Typography>
                <TextField
                  size="small"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="body2">Invoice No.</Typography>
                <TextField
                  size="small"
                  value={invoice}
                  onChange={(e) => setInvoice(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="body2">Date</Typography>
                <TextField
                  size="small"
                  value={date}
                  type="date"
                  onChange={(e) => setDate(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="body2">Customers P.O Number</Typography>
                <TextField
                  size="small"
                  value={cpo}
                  onChange={(e) => setCpo(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="body2">Our P.O Number</Typography>
                <TextField
                  size="small"
                  value={po}
                  onChange={(e) => setPo(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="body2">Machine Type</Typography>
                <TextField
                  size="small"
                  value={machine}
                  onChange={(e) => setMachine(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2">Machine Make</Typography>
                <TextField
                  size="small"
                  value={machineMake}
                  onChange={(e) => setMachineMake(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2">Machine Model</Typography>
                <TextField
                  size="small"
                  value={machineModel}
                  onChange={(e) => setMachineModel(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2">Exchange Type</Typography>
                <TextField
                  size="small"
                  id="select"
                  select
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="dollar2naira">Dollar To Naira</MenuItem>
                  <MenuItem value="pounds2naira">Pounds To Naira</MenuItem>
                  <MenuItem value="euro2naira">Euro To Naira</MenuItem>
                  <MenuItem value="naira2dollar">Naira To Dollar</MenuItem>
                  <MenuItem value="naira2pounds">Naira To Pounds</MenuItem>
                  <MenuItem value="naira2euro">Naira To Euro</MenuItem>
                  <MenuItem value="dollar2euro">Dollar To Euro</MenuItem>
                  <MenuItem value="euro2dollar">Euro To Dollar</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2">Exchange Rate</Typography>
                <TextField
                  size="small"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                  name="numberformat"
                  InputProps={{
                    inputComponent: NumberFormatCustom1,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2">VAT</Typography>
                <TextField
                  size="small"
                  value={vat}
                  onChange={(e) => setVat(e.target.value)}
                  fullWidth
                  name="numberformat"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2">Freight Charge</Typography>
                <TextField
                  size="small"
                  value={freight}
                  onChange={(e) => setFreight(e.target.value)}
                  name="numberformat"
                  InputProps={{
                    inputComponent: NumberFormatCustom1,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2">Mark Up</Typography>
                <TextField
                  size="small"
                  value={mark}
                  onChange={(e) => setMark(e.target.value)}
                  fullWidth
                  name="numberformat"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="body2">Currency Type</Typography>
                <TextField
                  size="small"
                  id="select"
                  select
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="$">Dollar $</MenuItem>
                  <MenuItem value="£">Pounds £</MenuItem>
                  <MenuItem value="€">Euro €</MenuItem>
                  <MenuItem value="₦">Naira ₦</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="body2">Signed By: Name</Typography>
                <TextField
                  size="small"
                  value={sign}
                  onChange={(e) => setSign(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="body2">Signed By: Position</Typography>
                <TextField
                  size="small"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Typography variant="h6" sx={{ marginTop: 3 }}>
              Description
            </Typography>
            <div style={{ border: '1px solid' }} />
            {inputDescription.map((input, i) => {
              return (
                <div key={i}>
                  <Grid container spacing={2}>
                    <Grid item md={10}>
                      <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
                        <Grid item xs={12} sm={6} md={8} lg={8}>
                          <Typography variant="body2">Description</Typography>
                          <TextField
                            size="small"
                            name="description"
                            value={input.description}
                            onChange={(e) => handleFormChange(i, e)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Typography variant="body2">Quantity</Typography>
                          <TextField
                            size="small"
                            name="quantity"
                            value={input.quantity}
                            onChange={(e) => handleFormChange(i, e)}
                            InputProps={{
                              inputComponent: NumberFormatCustom1,
                            }}
                            fullWidth
                          />
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Typography variant="body2">Invoice No</Typography>
                          <TextField
                            size="small"
                            value={input.invoice}
                            onChange={(e) => handleFormChange(i, e)}
                            name="invoice"
                            fullWidth
                          />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Typography variant="body2">PO No</Typography>
                          <TextField
                            size="small"
                            name="po"
                            value={input.po}
                            onChange={(e) => handleFormChange(i, e)}
                            fullWidth
                          />
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Typography variant="body2">Unit Price</Typography>
                          <TextField
                            size="small"
                            name="price"
                            value={input.price}
                            InputProps={{
                              inputComponent: NumberFormatCustom1,
                            }}
                            onChange={(e) => handleFormChange(i, e)}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      md={1}
                      sm={1}
                      sx={{
                        // display: 'block',
                        // justifyContent: 'center',
                        // alignItems: 'center',
                        margin: 'auto',
                      }}
                    >
                      <IconButton color="error" onClick={() => removeField(i)}>
                        <DeleteIcon />
                      </IconButton>{' '}
                    </Grid>
                  </Grid>
                  <div style={{ border: '1px solid', margin: '12px' }} />
                </div>
              )
            })}
            <Grid
              container
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <Button
                  size="medium"
                  variant="contained"
                  onClick={addField}
                  sx={{
                    backgroundColor: '#ee82ee',
                    '&:hover': {
                      backgroundColor: '#ee82ee',
                      boxShadow: 'none',
                    },
                  }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
            <Box mt={2} width="100%" display="flex" justifyContent="right">
              {!isPending && (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ color: '#fff', mt: 2 }}
                >
                  Submit Report
                </Button>
              )}

              {isPending && (
                <Button
                  variant="contained"
                  sx={{ pr: 5, pl: 5, backgroundColor: '#fff' }}
                >
                  <CircularProgress size={25} />
                </Button>
              )}
            </Box>
          </Paper>
        </Box>
      </Container>
      {message && (
        <Modal
          handleClickOpen={handleClickOpen}
          open={open}
          handleClose={handleClose}
          message={message}
          type={'Success'}
        />
      )}
      {isError && (
        <Modal
          handleClickOpen={handleClickOpen}
          open={open}
          handleClose={handleClose}
          message={isError}
          type={'Error'}
        />
      )}
    </Page>
  )
}

export default Form
