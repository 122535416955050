import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router'
import { Container } from '@mui/system'
import { Paper, Typography, Grid, Button } from '@mui/material'

//Components
import Page from '../components/Page'

//Hooks
import { useDocument } from '../hooks/useDocument'

function Report() {
  const [companyName, setCompanyName] = useState('')
  const [branch, setBranch] = useState('')
  const [fault, setFault] = useState('')
  const [inspection, setInspection] = useState()
  const [recommendation, setRecommendation] = useState('')
  const [conclusion, setConclusion] = useState('')
  const [equipment, setEquipment] = useState('')
  const [equipmentModel, setEquipmentModel] = useState('')
  const [equipmentMake, setEquipmentMake] = useState('')
  const [equipmentSN, setEquipmentSN] = useState('')
  const [date, setDate] = useState('')
  const [service, setService] = useState('')
  const [rConclusion, setRConclusion] = useState('')
  const [rRecommendation, setRRecommendation] = useState('')
  const [rDate, setRDate] = useState('')
  const [history, setHistory] = useState('')
  const [observation, setObservation] = useState('')
  const [part, setPart] = useState('')
  const [carried, setCarried] = useState('')

  const { id, company } = useParams()
  const navigate = useNavigate()
  const { document } = useDocument(company)

  useEffect(() => {
    const detail = async () => {
      try {
        const docSnap = await document(id)
        // console.log(docSnap.data())
        setCompanyName(docSnap.data().companyName)
        setBranch(docSnap.data().branch)
        setFault(docSnap.data().fault)
        setInspection(docSnap.data().inspection)
        setRecommendation(docSnap.data().recommendation)
        setConclusion(docSnap.data().conclusion)
        setEquipment(docSnap.data().equipment)
        setEquipmentModel(docSnap.data().equipmentModel)
        setEquipmentMake(docSnap.data().equipmentMake)
        setEquipmentSN(docSnap.data().equipmentSN)
        setDate(docSnap.data().date)
        setService(docSnap.data().service)
        setRConclusion(docSnap.data().rConclusion)
        setRRecommendation(docSnap.data().rRecommendation)
        setRDate(docSnap.data().rDate)
        setHistory(docSnap.data().history)
        setObservation(docSnap.data().observation)
        setPart(docSnap.data().part)
        setCarried(docSnap.data().carried)
      } catch (error) {
        console.log(error)
      }
    }
    if ((id !== undefined || id !== '') && id !== ':id') {
      detail()
    }

    // eslint-disable-next-line
  }, [id])

  return (
    <Page title="Report">
      <Container maxWidth="xl">
        <Paper elevation={6} sx={{ p: 4 }}>
          <Grid
            container
            spacing={3}
            sx={{
              marginBottom: 3,
              mt: 3,
              justifyContent: 'space-between',
            }}
          >
            <Grid item>
              <Button
                variant="contained"
                onClick={() =>
                  navigate(`/dashboard/inspection-print/${id}/${company}`)
                }
              >
                View Inspection for Print
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => navigate(`/dashboard/update/${id}/${company}`)}
              >
                Update Repairs
              </Button>
            </Grid>
          </Grid>
          <Grid container sx={{ border: '1px solid' }}>
            <Grid
              item
              xs={12}
              sm={7}
              md={7}
              sx={{ display: 'flex', border: '1px solid', padding: 1 }}
            >
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Company Name: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {companyName}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              sx={{ display: 'flex', border: '1px solid', padding: 1 }}
            >
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Location: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {branch}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{ display: 'flex', border: '1px solid', padding: 1 }}
            >
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Equipment Type: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {equipment}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{ display: 'flex', border: '1px solid', padding: 1 }}
            >
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Date Started: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {date}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{ display: 'flex', border: '1px solid', padding: 1 }}
            >
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Date Completed: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {rDate}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{ display: 'flex', border: '1px solid', padding: 1 }}
            >
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Equipment Make: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {equipmentMake}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{ display: 'flex', border: '1px solid', padding: 1 }}
            >
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Equipment Model: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {equipmentModel}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{ display: 'flex', border: '1px solid', padding: 1 }}
            >
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Equipment Serial No: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {equipmentSN}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                border: '1px solid',
                padding: 3,
              }}
            >
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Inspected By:</b> {carried}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                border: '1px solid',
                padding: 3,
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Typography variant="body" sx={{ margin: 1, color: '#2065d1' }}>
                <b>Brief Summary Of Crane Inspection</b>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ border: '1px solid', padding: 3 }}>
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Fault Reported: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {fault}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ border: '1px solid', padding: 3 }}>
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>History: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {history}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ border: '1px solid', padding: 3 }}>
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Inspection: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {inspection}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ border: '1px solid', padding: 3 }}>
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Observation: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {observation}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} sx={{ border: '1px solid', padding: 3 }}>
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Recommendation: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {recommendation}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ border: '1px solid', padding: 3 }}>
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Conclusion: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {conclusion}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ border: '1px solid', padding: 3 }}>
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Spare Parts Needed: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {part}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ border: '1px solid', padding: 3 }}>
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Service Carried Out: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {service}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ border: '1px solid', padding: 3 }}>
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Service Recommendation: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {rRecommendation}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ border: '1px solid', padding: 3 }}>
              <Typography variant="body" sx={{ margin: 1 }}>
                <b>Service Conclusion: </b>{' '}
              </Typography>
              <Typography variant="body" sx={{ margin: 1 }}>
                {' '}
                {rConclusion}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Page>
  )
}

export default Report
