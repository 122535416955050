import { Navigate, useRoutes } from 'react-router-dom'
// layouts
import DashboardLayout from './layouts/dashboard'
import LogoOnlyLayout from './layouts/LogoOnlyLayout'
//
import Login from './pages/Login'
import NotFound from './pages/Page404'
import Register from './pages/Register'
import DashboardApp from './pages/DashboardApp'
import AddCompany from './pages/AddCompany'
import Company from './pages/Company'
import Inspection from './pages/Inspection'
import Report from './pages/Report'
import Update from './pages/Update'
import ReportPrint from './pages/ReportPrint'
import Inventory from './pages/Inventory'
import Service from './pages/Service'
import Inventories from './pages/Inventories'
import Form from './pages/Form'
import ServiceForm from './pages/ServiceForm'
import InventoryPrint from './pages/InventoryPrint'
import ServicePrint from './pages/ServicePrint'
import InspectionPrint from './pages/InspectionPrint'
import ForgotPassword from './pages/ForgotPassword'

//Hooks
import { useAuthContext } from './hooks/useAuthContext'

// ----------------------------------------------------------------------

export default function Router() {
  const { user } = useAuthContext()
  return useRoutes([
    {
      path: '/dashboard',
      element: user ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        {
          path: 'app',
          element: <DashboardApp />,
        },
        {
          path: 'add-company/:id',
          element: <AddCompany />,
        },
        {
          path: 'company/:id',
          element: <Company />,
        },
        {
          path: 'inspection/:id',
          element: <Inspection />,
        },
        {
          path: 'inspection-print/:id/:company',
          element: <InspectionPrint />,
        },
        {
          path: 'report/:id/:company',
          element: <Report />,
        },
        {
          path: 'update/:id/:company',
          element: <Update />,
        },
        {
          path: 'report-print/:id/:company',
          element: <ReportPrint />,
        },
        {
          path: 'inventory',
          element:
            (user && user.email === 'akolisagroup@gmail.com') ||
            (user && user.photoURL === 'Account') ? (
              <Inventories />
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: 'inventory-invoice',
          element:
            (user && user.email === 'akolisagroup@gmail.com') ||
            (user && user.photoURL === 'Account') ? (
              <Inventory />
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: 'inventory-service',
          element:
            (user && user.email === 'akolisagroup@gmail.com') ||
            (user && user.photoURL === 'Account') ? (
              <Service />
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: 'inventory-form/:id',
          element:
            (user && user.email === 'akolisagroup@gmail.com') ||
            (user && user.photoURL === 'Account') ? (
              <Form />
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: 'inventory-service-form/:id',
          element:
            (user && user.email === 'akolisagroup@gmail.com') ||
            (user && user.photoURL === 'Account') ? (
              <ServiceForm />
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: 'inventory-print/:id',
          element:
            (user && user.email === 'akolisagroup@gmail.com') ||
            (user && user.photoURL === 'Account') ? (
              <InventoryPrint />
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: 'inventory-service-print/:id',
          element:
            (user && user.email === 'akolisagroup@gmail.com') ||
            (user && user.photoURL === 'Account') ? (
              <ServicePrint />
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },

        {
          path: 'login',
          element: !user ? <Login /> : <Navigate to="/dashboard/app" />,
        },
        {
          path: 'forgot-password',
          element: !user ? (
            <ForgotPassword />
          ) : (
            <Navigate to="/dashboard/app" />
          ),
        },
        {
          path: 'register',
          element:
            user && user.email === 'akolisagroup@gmail.com' ? (
              <Register />
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}
