import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container } from '@mui/system'
import { Box, Paper, Typography, Button, Grid } from '@mui/material'
import Page from '../components/Page'
import InventoryTable from '../components/InventoryTable'

//Hooks
import { useCollection } from '../hooks/useCollection'

function Inventory() {
  const navigate = useNavigate()
  const { documents } = useCollection('Inventory')
  return (
    <Page title="Inventory">
      <Container maxWidth="xl">
        <Box>
          <Paper elevation={6} sx={{ p: 4 }}>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between', marginBottom: 1, mt: 1 }}
            >
              <Grid item xs={12} sm={3} md={3}>
                <Typography variant="h4" sx={{ mb: 1 }}>
                  Inventory
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => navigate('/dashboard/inventory-form/:id')}
                >
                  Create Inventory
                </Button>
              </Grid>
            </Grid>
            <InventoryTable data={documents} />
          </Paper>
        </Box>
      </Container>
    </Page>
  )
}

export default Inventory
