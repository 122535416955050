import { useState } from 'react'
import { Link } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import PrintIcon from '@mui/icons-material/Print'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteModal from './DeleteModal'

export default function Table({ data, ...others }) {
  const [responsive] = useState('simple')
  const [tableBodyHeight] = useState('auto')
  const [tableBodyMaxHeight] = useState('')
  const [searchBtn] = useState(true)
  const [downloadBtn] = useState(true)
  const [printBtn] = useState(true)
  const [viewColumnBtn] = useState(true)
  const [filterBtn] = useState(true)
  const columns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'id',
      hide: true,
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
    {
      name: 'companyName',
      label: 'Company',
      options: {
        filter: true,
        sort: false,
        viewColumns: false,
      },
    },
    {
      name: 'branch',
      label: 'Branch',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'equipment',
      label: 'Equipment',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'date',
      label: 'Date',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: '',
      options: {
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <button
                style={{
                  border: 'none',
                  background: 'transparent',
                  cursor: 'pointer',
                }}
              >
                <Link
                  to={`/dashboard/report/${tableMeta.rowData[1]}/${tableMeta.rowData[2]}`}
                  style={{ textDecoration: 'none', color: '#2065d1' }}
                >
                  <VisibilityIcon
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '#2065d1',
                    }}
                  />
                </Link>
              </button>
              <button
                style={{
                  border: 'none',
                  background: 'transparent',
                  cursor: 'pointer',
                }}
              >
                <Link
                  to={`/dashboard/report-print/${tableMeta.rowData[1]}/${tableMeta.rowData[2]}`}
                  style={{ textDecoration: 'none', color: '#2065d1' }}
                >
                  <PrintIcon
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '#2065d1',
                    }}
                  />
                </Link>
              </button>
              <DeleteModal
                id={tableMeta.rowData[1]}
                data={tableMeta.rowData[2]}
              />
            </div>
          )
        },
      },
    },
  ]

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    selectableRows: 'none',
    filter: filterBtn,
    filterType: 'dropdown',
    rowHover: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: '',
      direction: 'asc',
    },
  }
  const muiCache = createCache({
    key: 'mui',
    prepend: true,
  })
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: '12px !important',
              //   marginBottom: 8,
              marginTop: '8px !important',
              paddingTop: '12px !important',
              backgroundColor: '#2065d1',
              color: '#fff',
              fontSize: '20px !important',
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              backgroundColor: '#fff',
            },
          },
        },
      },
    })
  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={getMuiTheme}>
        <MUIDataTable
          title={'Report Table'}
          data={data}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    </CacheProvider>
  )
}
