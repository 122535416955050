import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Button } from '@mui/material'
import Inspection from '../components/Inspection'

function ReportPrint() {
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  return (
    <div className="bg-gray-200 p-6">
      <Button type="button" variant="contained" onClick={handlePrint}>
        {' '}
        Print Report{' '}
      </Button>
      <Inspection ref={componentRef} />
    </div>
  )
}
export default ReportPrint
